import React from 'react';
import { Table } from 'antd';

interface Asset {
  key: string;
  asset: string;
  amount: string;
  value: string;
}

const AssetList: React.FC = () => {
  const dataSource: Asset[] = [
    {
      key: '1',
      asset: 'Sats',
      amount: '1000 SATS',
      value: 'Equivalent USD/EUR',
    },
    {
      key: '2',
      asset: 'Rats',
      amount: '500 RATS',
      value: 'Equivalent USD/EUR',
    },
    // Ajoutez d'autres assets ici
  ];

  const columns = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      key: 'asset',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <div>
      <h2>Asset List</h2>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};

export default AssetList;
