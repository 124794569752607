import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { message } from 'antd';

declare global {
  interface Window { unisat: any; }
}

interface Balance {
  confirmed: number;
  unconfirmed: number;
  total: number;
}

interface Staked {
  cltv: number;
  btc: number;
  fcdp: number;
  oshi: number;
  sats: number;
  ordi: number;
  pizza: number;
  dog: number;
  dswp: number;
  trac: number;
}

interface ConnectionContextProps {
  isUnisatInstalled: boolean;
  isConnected: boolean;
  balance: Balance;
  currentAccount: string;
  network: string;
  connectWallet: () => Promise<void>;
  stakingAddress: string | null; 
  stakedTokens: Partial<Staked>;
  disconnectWallet: () => void;
  switchNetwork: (network: string) => Promise<void>;
  sendInscription: (address: string, inscriptionId: string, options?: any) => Promise<string>;
  signMessage: (msg: string, type?: string) => Promise<string>;
  pushTx: (options: any) => Promise<string>;
  signPsbt: (psbtHex: string, options?: any) => Promise<string>;
  getInscriptions: (cursor: number, size: number) => Promise<any>;
  getPublicKey: () => Promise<string | null>;
}

export const ConnectionContext = createContext<ConnectionContextProps>({} as ConnectionContextProps);

export const ConnectionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isUnisatInstalled, setIsUnisatInstalled] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [balance, setBalance] = useState<Balance>({ confirmed: 0, unconfirmed: 0, total: 0 });
  const [currentAccount, setCurrentAccount] = useState('');
  const [network, setNetwork] = useState('livenet');
  const [stakingAddress, setStakingAddress] = useState<string | null>(null);
  const [stakedTokens, setStakedTokens] = useState<Partial<Staked>>({});

  useEffect(() => {
    const initUnisat = async () => {
      if (window.unisat) {
        setIsUnisatInstalled(true);

        try {
          const net = await window.unisat.getNetwork();
          setNetwork(net);
          await connectWallet();
        } catch (error) {
          message.error('Could not connect to Unisat');
        }
      } else {
        await connectWallet();
      }
    };

    initUnisat();

    if (window.unisat) {
      window.unisat.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      if (window.unisat) {
        window.unisat.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, []);

  const handleAccountsChanged = async (accounts: string[]) => {
    if (accounts.length > 0) {
      await updateAccountData(accounts[0]);
    } else {
      resetState();
    }
  };

  const resetState = () => {
    setIsConnected(false);
    setCurrentAccount('');
    setBalance({ confirmed: 0, unconfirmed: 0, total: 0 });
    setStakingAddress(null);
    setStakedTokens({});
  };

  const updateAccountData = async (account: string) => {
    setCurrentAccount(account);
    try {
      const balance = await window.unisat.getBalance(account);
      setBalance(balance);
      await fetchStakinginfo();
    } catch (error) {
      message.error('Failed to update account data');
    }
  };

  const fetchStakinginfo = async (): Promise<void> => {
    try {
      const pubKey = await getPublicKey();
      if (!pubKey) throw new Error('Public key not available');

      const blockheight = 858052;
      const campaignIds = [
        { id: 6, ticker: "oshi" }, { id: 5, ticker: "fcdp" }, { id: 3, ticker: "cltv" },
        { id: 4, ticker: "btc" }, { id: 7, ticker: "sats" }, { id: 12, ticker: "ordi" },
        { id: 11, ticker: "pizza" }, { id: 8, ticker: "dog" }, { id: 10, ticker: "dswp" },
        { id: 9, ticker: "trac" }
      ];

      const promises = campaignIds.map(campaign => fetchCampaignData(pubKey, blockheight, campaign.ticker));
      const results = await Promise.all(promises);

      const updatedStakedTokens: Partial<Staked> = {};
      results.forEach(result => {
        if (result) {
          updatedStakedTokens[result.ticker as keyof Staked] = result.stakedAmount;
        }
      });

      setStakedTokens(updatedStakedTokens);
    } catch (error) {
      console.error('Error fetching staking info:', error);
      message.error('Failed to fetch staking info.');
    }
  };

  const fetchCampaignData = async (pubKey: string, blockheight: number, ticker: string) => {
    try {
      const response = await fetch(`/api/stakinginfo`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pubKey, blockheight, ticker })
      });

      if (response.ok) {
        const stakingData = await response.json();
        const stakedAmount = stakingData?.data?.overallBalance !== undefined 
          ? parseFloat(stakingData.data.overallBalance)
          : 0;

        if (stakingData?.stakingAddressData) {
          setStakingAddress(stakingData.stakingAddressData);
        }

        return { ticker, stakedAmount };
      } else {
        console.error(`Failed to fetch staking info for ${ticker}`);
        return { ticker, stakedAmount: 0 };
      }
    } catch (error) {
      console.error(`Error fetching staking info for ${ticker}:`, error);
      return { ticker, stakedAmount: 0 };
    }
  };

  const connectWallet = async () => {
    try {
      const accounts = await window.unisat.requestAccounts();
      setIsConnected(true);
      await handleAccountsChanged(accounts);
      const disclaimer = "Disclaimer: Your engagement with the CLTV experimental protocol implies your acknowledgment and acceptance of its experimental nature. You recognize that this protocol may contain flaws, vulnerabilities, and unforeseen consequences. By using the CLTV experimental protocol, you acknowledge the inherent risks involved, including potential financial loss and security breaches. You understand that there are no guarantees regarding its performance, reliability, or security. It is your sole responsibility to assess and mitigate the risks associated with its use. CLTV team and its affiliates shall not be liable for any damages arising from your use of the CLTV experimental protocol. By proceeding, you expressly acknowledge and accept these terms and assume all associated risks.";
      await signMessage(disclaimer);
    } catch (error) {
      message.error('Could not connect to Unisat or sign message');
    }
  };

  const getInscriptions = async (cursor: number, size: number) => {
    return apiWrapper(window.unisat.getInscriptions, cursor, size);
  };

  const disconnectWallet = () => {
    resetState();
  };

  const getPublicKey = async (): Promise<string | null> => {
    return apiWrapper(window.unisat.getPublicKey);
  };

  const switchNetwork = async () => {
    if (!window.unisat) {
      message.error('UniSat Wallet not installed');
      return;
    }

    try {
      const newNetwork = network === 'livenet' ? 'testnet' : 'livenet';
      await window.unisat.switchNetwork(newNetwork);
      setNetwork(newNetwork);

      const accounts = await window.unisat.getAccounts();
      handleAccountsChanged(accounts);
    } catch (error) {
      console.error('Error switching network:', error);
      message.error('Could not switch network');
    }
  };

  const apiWrapper = async (method: (...args: any[]) => Promise<any>, ...args: any[]) => {
    if (!window.unisat) throw new Error('UniSat Wallet not installed');
    try {
      return await method(...args);
    } catch (error) {
      console.error(`Error in ${method.name}:`, error);
      throw error;
    }
  };

  const sendInscription = (address: string, inscriptionId: string, options?: any) => 
    apiWrapper(window.unisat.sendInscription, address, inscriptionId, options);

  const signMessage = (msg: string, type?: string) => 
    apiWrapper(window.unisat.signMessage, msg, type);

  const pushTx = (options: any) => 
    apiWrapper(window.unisat.pushPsbt, options.psbtHex);

  const signPsbt = (psbtHex: string, options?: any) => 
    apiWrapper(window.unisat.pushPsbt, psbtHex);

  return (
    <ConnectionContext.Provider value={{
      isUnisatInstalled,
      isConnected,
      balance,
      currentAccount,
      network,
      connectWallet,
      disconnectWallet,
      switchNetwork,
      sendInscription,
      signMessage,
      pushTx,
      getInscriptions,
      getPublicKey,
      signPsbt,
      stakingAddress,
      stakedTokens
    }}>
      {children}
    </ConnectionContext.Provider>
  );
};

export default ConnectionProvider;