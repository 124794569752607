import React from 'react';
import { Layout, Menu } from 'antd';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ConnectionProvider from './components/ConnectionContext';
import Connect from './components/Connect';
import Menuinterface from './components/Menuinterface';
import Borrow from './components/Borrow';
import AssetList from './components/AssetList';
import { TwitterOutlined, BookOutlined, SendOutlined, GithubOutlined } from '@ant-design/icons';

const { Content, Footer } = Layout;

const App = () => {
  return (
    <ConnectionProvider>
      <Router>
        <Layout className="layout">
          <Layout>
            <Content style={{ padding: '5px',backgroundColor:"#f4f4f4",minHeight:'880px'  }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {/* Logo */}
                     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                       <img src="cltv_logo.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%', maxHeight: '55px' }} />
                       <div style={{ display: 'flex', gap: '10px', marginTop: '10px', justifyContent: 'center' }}>
                         <a href="https://t.me/cltv_finance" target="_blank" rel="noopener noreferrer">
                           <SendOutlined style={{ fontSize: '24px', color: '#f7931a' }} />
                         </a>
                         <a href="https://twitter.com/cltv_finance" target="_blank" rel="noopener noreferrer">
                           <TwitterOutlined style={{ fontSize: '24px', color: '#f7931a' }} />
                         </a>
                         <a href="https://cltv-finance.gitbook.io/cltv-finance" target="_blank" rel="noopener noreferrer">
                           <BookOutlined style={{ fontSize: '24px', color: '#f7931a' }} />
                         </a>
                         <a href="https://github.com/Larkhell/redeem-script-generator" target="_blank" rel="noopener noreferrer">
                           <GithubOutlined style={{ fontSize: '24px', color: '#f7931a' }} />
                         </a>
                       </div>
                     </div>
                     

                    {/* Menu centré */}
        {/*            <Menu mode="horizontal" defaultSelectedKeys={['1']} style={{ flex: 1,fontSize:'19px', justifyContent: 'center', display: 'flex',backgroundColor:"transparent",color:'#f7931a' }}>
                      <Menu.Item key="1">
                        <Link to="/"><b>Home</b></Link>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <Link to="/Dashboard"><b>Dashboard</b></Link>
                      </Menu.Item>
                    </Menu>*/}

                    {/* Bouton Connect */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                      <Connect />
                    </div>
                  </div>

              <Routes>
                <Route path="/Menu" element={<Menuinterface />} />
                <Route path="/" element={<Borrow />} />
                <Route path="/assets" element={<AssetList />} />
              </Routes>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Powered by CLTV 2024</Footer>
          </Layout>
        </Layout>
      </Router>
    </ConnectionProvider>
  );
};

export default App;
