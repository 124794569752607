import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Space, Typography, message, Row, Col, Modal } from 'antd';
import { ConnectionContext } from './ConnectionContext';

const { Text } = Typography;

interface Rewards {
  cltv: number;
  btc: number;
  fcdp: number;
  oshi: number;
  sats: number;
  ordi: number;
  pizza: number;
  dog: number;
  dswp: number;
  trac: number;
}

interface RewardResult {
  ticker: string;
  rewardAmount: number;
}

const Connect: React.FC = () => {
  const {
    isConnected,
    currentAccount,
    connectWallet,
    disconnectWallet,
    stakingAddress,
    stakedTokens,
  } = useContext(ConnectionContext);

  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState<Rewards>({
    cltv: 0, btc: 0, fcdp: 0, oshi: 0,
    sats: 0, ordi: 0, pizza: 0, dog: 0,
    dswp: 0, trac: 0
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const totalRewards = Object.values(rewards).reduce((acc, curr) => acc + curr, 0);

  useEffect(() => {
    fetchRewardInfo();
    let intervalId: NodeJS.Timeout | number;
    if (isConnected && currentAccount) {
      intervalId = setInterval(fetchRewardInfo, 10 * 60 * 1000); // 10 minutes
    }
    return () => clearInterval(intervalId);
  }, [isConnected, currentAccount]);

  const fetchRewardInfo = async () => {
    if (!currentAccount) return;

    const campaignIds = [
      { id: 3, ticker: "cltv" }, { id: 4, ticker: "btc" }, { id: 5, ticker: "fcdp" }, { id: 6, ticker: "oshi" },
      { id: 7, ticker: "sats" }, { id: 12, ticker: "ordi" }, { id: 11, ticker: "pizza" },
      { id: 8, ticker: "dog" }, { id: 10, ticker: "dswp" }, { id: 9, ticker: "trac" }
    ];

    const promises = campaignIds.map(async (campaign) => {
      const response = await fetch(`/api/rewardinfo`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ campaignId: campaign.id.toString(), taproot: currentAccount })
      });

      if (response.ok) {
        const rewardData = await response.json();
        return { ticker: campaign.ticker, rewardAmount: rewardData ? rewardData : 0 };
      } else {
        console.error('Error fetching reward info:', response.statusText);
        return null;
      }
    });

    const results: (RewardResult | null)[] = await Promise.all(promises);

    const updatedRewards: Partial<Rewards> = {};
    results.forEach(result => {
      if (result) {
        updatedRewards[result.ticker as keyof Rewards] = result.rewardAmount;
      }
    });
    setRewards(prevRewards => ({ ...prevRewards, ...updatedRewards }));
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentAccount).then(() => {
      message.success('Address copied to clipboard');
    });
  };

  const showModal = () => setIsModalVisible(true);
  const handleClose = () => setIsModalVisible(false);

  return isConnected ? (
    <Card style={{ position: 'absolute', top: '0px', right: '0px', backgroundColor: '#0d3a4d', color: '#f4f4f4', width: '300px' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
          <a href={`https://mempool.space/address/${stakingAddress}`} target="_blank" rel="noopener noreferrer">
            <Text style={{ color: '#f7931a' }}>{stakingAddress ? `${stakingAddress.substring(0, 9)}...` : 'Staking Address'}</Text>
          </a>
          <Button onClick={disconnectWallet} style={{ backgroundColor: 'transparent', color: '#f4f4f4', border: 'none' }}>Disconnect</Button>
        </Space>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Text style={{ color: '#f4f4f4' }}>Total Rewards: {loading ? 'Loading...' : totalRewards}</Text>
            </Col>
          </Row>
{/*          <Button onClick={showModal} style={{ backgroundColor: '#f4f4f4', color: '#0d3a4d', margin: '10px 0', borderRadius: '20px' }}>
            View Details
          </Button>*/}
          <Text style={{ color: '#f7931a', textAlign: 'center', margin: '5%' }}>Locked assets will be claimable after the 2nd staking campaign planned until 23 August 2024</Text>
        </div>
      </Space>                  
      <Modal
        title="Reward Details"
        visible={isModalVisible}
        onCancel={handleClose}
        centered
        footer={null}
        width={800}
      >
        <div style={{ padding: '20px', maxHeight: '70vh', overflowY: 'auto' }}>
          {Object.entries(rewards).map(([ticker, amount]) => (
            amount !== 0 && (
              <div key={ticker} style={{ border: '1px solid #e0e0e0', borderRadius: '10px', marginBottom: '15px', padding: '20px', backgroundColor: '#fafafa' }}>
                <Row align="middle" justify="space-between" gutter={32}>
                  <Col span={4} style={{ textAlign: 'center' }}>
                    <Text strong style={{ fontSize: '1.2em' }}>{stakedTokens[ticker as keyof Rewards] || '0'}</Text>
                  </Col>
                  <Col span={4} style={{ textAlign: 'center' }}>
                    <img
                      src={`/${ticker}.png`}
                      alt={ticker.toUpperCase()}
                      style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    />
                  </Col>
                  <Col span={4} style={{ textAlign: 'center' }}>
                    <Text strong style={{ fontSize: '1.2em' }}>Locked</Text>
                  </Col>
                  <Col span={12} style={{ textAlign: 'center' }}>
                    <Text strong style={{ fontSize: '1.2em' }}>{amount} <span style={{ color: '#0d3a4d' }}>CLTV</span> rewards</Text>
                  </Col>
                </Row>
              </div>
            )
          ))}
        </div>
      </Modal>
    </Card>
  ) : (
    <Button style={{ fontWeight: '700', width: '100%', maxWidth: '29%', backgroundColor: '#0d3a4d', color: '#f4f4f4', marginLeft: '5%' }} onClick={connectWallet}>
      <span style={{ fontSize: 'calc(12px + 0.2vw)' }}>Connect</span>
    </Button>
  );
};

export default Connect;
