import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Input,Card, Button, Space, message, Typography, Modal } from 'antd';
import { ConnectionContext } from './ConnectionContext';
import axios from 'axios';
import './Menuinterface.css';

const { Paragraph, Text } = Typography;

const pageSize = 100;

interface Inscription {
  inscriptionId: string;
  content: string;
  jsonData?: JsonData;
  location: string;
}

interface JsonData {
  p: string;
  op: string;
  tick: string;
  amt: string;
}

const stakingOptions = [
  { title: 'OSHI Party',label:'OSHI-SATS LP', value: 3 },
  { title: 'WEI Party',label:'WEI-SATS LP', value: 6 },
  { title: 'SATS Party',label:'WEI-OSHI LP', value: 18 }
];



const Menuinterface: React.FC = () => {
  const { isConnected, currentAccount, getInscriptions } = useContext(ConnectionContext);
  const [selectedInscriptions, setSelectedInscriptions] = useState<Inscription[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inscriptions, setInscriptions] = useState<Inscription[]>([]);
  const [stakingPeriod, setStakingPeriod] = useState<number | null>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [numericValue, setNumericValue] = useState(0);
  const [isStaked, setIsStaked] = useState(false);

  useEffect(() => {
    if (isConnected && currentAccount) {
      loadInscriptions();
    }
  }, [isConnected, currentAccount]);

  const loadInscriptions = useCallback(async () => {
    const totalInscriptions = (await getInscriptions(0, pageSize)).total;
    let allInscriptions = [];

    for (let i = 0; i < Math.ceil(totalInscriptions / pageSize); i++) {
      const response = await getInscriptions(i * pageSize, pageSize);
      allInscriptions.push(...response.list);
    }

    const validInscriptions = await enrichInscriptions(allInscriptions);
    setInscriptions(validInscriptions);
  }, [getInscriptions]);

  const enrichInscriptions = async (allInscriptions: Inscription[]) => {
    const enriched: Inscription[] = [];

    for (const inscription of allInscriptions) {
      const jsonData = await fetchContentData(inscription.content);

      if (jsonData && jsonData.p === 'brc-20' && jsonData.tick.toLowerCase() === 'fcdp' && jsonData.op === 'transfer') {
        enriched.push({ ...inscription, jsonData });
      }
    }

    return enriched;
  };

  const fetchContentData = async (contentUrl: string) => {
    try {
      const response = await axios.get(contentUrl);
      return response.data;
    } catch (error) {
      console.error('Error fetching content data:', error);
      return null;
    }
  };

  const toggleInscriptionSelection = (inscription: Inscription) => {
    setSelectedInscriptions((prev) =>
      prev.some((selected) => selected.inscriptionId === inscription.inscriptionId)
        ? prev.filter((selected) => selected.inscriptionId !== inscription.inscriptionId)
        : [...prev, inscription]
    );
  };


const fetchUtxoData = async (txid: string): Promise<string> => {
  try {
    // Fetch the raw transaction data in hex format
    const url = `https://blockstream.info/api/tx/${txid}/hex`;
    const response = await axios.get(url, { responseType: 'text' });

    if (response.data) {
      return response.data; // The response is the raw transaction in hex
    } else {
      throw new Error(`Raw transaction data not found for txid: ${txid}`);
    }
  } catch (error) {
    console.error(`Error fetching raw transaction data for txid ${txid}:`, error);
    throw error;
  }
};
const fetchUtxoDetails = async (txid: string, vout: number): Promise<{ nonWitnessUtxo: Buffer, amount: number }> => {
  try {
    const url = `https://blockstream.info/api/tx/${txid}`;
    const response = await axios.get(url);
    if (response.data && response.data.vout && response.data.vout[vout]) {
      const nonWitnessUtxoData = await fetchUtxoData(txid);
      const amount = response.data.vout[vout].value; // Extract amount from vout
      return {
        nonWitnessUtxo: Buffer.from(nonWitnessUtxoData, 'hex'),
        amount
      };
    } else {
      throw new Error(`UTXO details not found for txid: ${txid} and vout: ${vout}`);
    }
  } catch (error) {
    console.error(`Error fetching UTXO details for txid ${txid} and vout ${vout}:`, error);
    throw error;
  }
};

const handleStake = async () => {
  if (numericValue <= 0) {
    console.error("Please enter a valid LP quantity to stake.");
    message.error("Please enter a valid LP quantity to stake.");
    return;
  }

  try {
      message.success(`Staking successful. Transaction hash: `);
      setIsModalVisible(false);
      setIsStaked(true);
  } catch (error) {
    console.error('Error in handleStake:', error);
    message.error(error instanceof Error ? error.message : 'An error occurred processing the staking request.');
  }
};
  const getCurrentBlockNumber = async (): Promise<number | null> => {
    try {
      const response = await fetch(`https://open-api.unisat.io/v1/indexer/blockchain/info`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ',
          'User-Agent': 'Unisat-Node-Client',
          'Content-Type': 'application/json'
        })
      });
      const data = await response.json();
      return data.code === 0 ? data.data.blocks : null;
    } catch (error) {
      console.error('Error fetching current block number:', error);
      return null;
    }
  };

  const showModal = (value: number,title:string) => {
    setModalTitle(title);
    setStakingPeriod(value);
    setIsModalVisible(true);
  };

 const handleNumericInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const newValue = Number(event.target.value);
  if (!isNaN(newValue)) { 
    setNumericValue(newValue);
  }
};
  const setMaxValue = () => {
    setNumericValue(100);
  };

  const renderInscriptionDetails = (jsonData: JsonData | null | undefined) => {
    if (!jsonData || !jsonData.tick || !jsonData.amt || !jsonData.op) {
      return <Paragraph>Loading...</Paragraph>;
    }

    return (
      <Paragraph>
        <Text strong>Ticker: </Text>{jsonData.tick.toUpperCase()}
        <br />
        <Text strong>Amount: </Text>{jsonData.amt}
        <br />
        <Text strong>Operation: </Text>{jsonData.op}
        <br />
      </Paragraph>
    );
  };

  return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <p><img src="logocook.png" alt="Logo" style={{ height: '300px' }} /></p>
        <h1>Select Your favorite Dishes</h1>
        <h3>Earn u$sat tokens by staking Unisat LP tokens !</h3>
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          {stakingOptions.map((option) => (
            <Card key={option.value} title={`Stake for ${option.title}`} style={{ width: 300, margin: '10px', cursor: 'pointer' }} onClick={() => showModal(option.value,option.label)} hoverable>
              <h3>Deposit {option.label}</h3>
              <Button type="primary">Stake</Button>
            </Card>
          ))}
          {isStaked && numericValue > 0 && (
            <Card style={{ width: 300, margin: '10px' }}>
              <h3>You have staked {numericValue} {modalTitle} tokens</h3>
            </Card>
          )}
        </div>
        <Modal style={{ textAlign: 'center', padding: '20px' }}
            title={`Deposit ${modalTitle} Tokens `}
            visible={isModalVisible} 
            onOk={handleStake} 
            onCancel={() => setIsModalVisible(false)}
            className="stake-modal"
          >
          <h2>Lorepsum ipsulum </h2>
          <Space>
            <Input 
              className="numeric-input"
              type="text"
              value={numericValue}
              onChange={handleNumericInputChange}
              addonAfter={modalTitle}
              placeholder={`Enter a value for ${modalTitle}`}
            />
            <Button 
              className="max-button"
              onClick={setMaxValue} 
              type="primary"
            >
              Max
            </Button>
          </Space>
        </Modal>
      </div>
  );
};

export default Menuinterface;